export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";
export const FETCH_CARDS_START = "FETCH_CARDS_START";
export const FETCH_CARDS_FAILURE = "FETCH_CARDS_FAILURE";
export const FETCH_CARDS_SUCCESS_ALL = "FETCH_CARDS_SUCCESS_ALL";

export const LOAD_MORE_SUCCESS = "LOAD_MORE_SUCCESS";
export const LOAD_MORE_START = "LOAD_MORE_START";
export const LOAD_MORE_FAILURE = "LOAD_MORE_FAILURE";
// export const LOAD_MORE_HIDE_BUTTON = "LOAD_MORE_HIDE_BUTTON";

export const LOAD_MORE = "LOAD_MORE";
export const SEARCH_INPUT = "SEARCH_INPUT";
export const RESET_SEARCH_INPUT = "RESET_SEARCH_INPUT";
